<template>
  <div id="login" class="hi100 login-phone">
    <div class="uf uf-ac uf-pc hi100 pl-2 pr-2">
      <div class="" v-if="winWidth>1336" style="margin-right: 10rem;" >
        <img src="~@/assets/img/login-left.png" alt="">
      </div>
      <div class="login-box p-5">
        <div class="logo-box uf uf-pc">
          <div class="wrap uf uf-ac uf-pc wi100 cp" title="前往首页" v-if="isH5">
            <img src="~@/assets/img/logo-sp.png" alt="">
          </div>
          <div class="wrap uf uf-ac uf-pc wi100 cp" title="前往首页" @click="$router.replace({name: 'index'})" v-else>
            <img src="~@/assets/img/logo-sp.png" alt="">
          </div>
        </div>
        <template>
          <div class="tac f18 fwb mb-4">忘记密码</div>
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" status-icon>
            <el-form-item prop="mobile">
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                v-model="dataForm.mobile">
              </el-input>
            </el-form-item>
            <el-form-item v-if="!isH5">
              <div><slider ref="slider"></slider></div>
            </el-form-item>
            <el-form-item prop="smsCode">
              <el-row :gutter="10">
                <el-col :span="14">
                  <el-input prefix-icon="el-icon-key" v-model="dataForm.smsCode" placeholder="短信验证码"></el-input>
                </el-col>
                <el-col :span="10">
                  <div class="qrcode">
                    <template v-if="smsSuc">
                      <el-button type="danger">{{ smsTime }}</el-button>
                    </template>
                    <template v-else>
                      <el-button type="text" @click="getMsgCode">获取短信验证码</el-button>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="新密码"
                type="password"
                prefix-icon="el-icon-lock"
                v-model="dataForm.password"
                show-password>
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input
                placeholder="确认密码"
                type="password"
                prefix-icon="el-icon-lock"
                v-model="dataForm.confirmPassword"
                show-password>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="wi100" @click="dataFormSubmit" :loading="subLoading">提 交</el-button>
        </template>
        <div class="tac mt-3">
          <a class="c-base cp f14" @click="$router.push({name: isH5 ? 'h5-login' : 'login'})">返回登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {encode64, getUUID} from '../../utils'
  import {isMobile} from '../../utils/validate'
  import Slider from './components/slider'

  export default {
    name: 'forget-password',
    components: {Slider},
    data () {
      const checkSmsCode = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('短信验证码不能为空'))
        } else {
          callback()
        }
      }
      const checkMobile = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入手机号码'))
        } else if (!isMobile(value)) {
          return callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      let validateConfirmPassword = (rule, value, callback) => {
        if (value === null || value.length < 6) {
          callback(new Error('密码长度应大于6位'))
        }
        let spaceReg = /\s+/g
        if (spaceReg.test(value)) {
          callback(new Error('不能包含空格'))
        }
        let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,30}$/
        if (!reg.test(value)) {
          callback(new Error('密码复杂度过低，需包含字母、数字、特殊符号'))
        }
        callback()
      }
      let passwordValidate2 = (rule, value, callback) => {
        if (this.dataForm.password !== value) {
          callback(new Error('确认密码与新密码不一致'))
        } else {
          callback()
        }
      }
      return {
        accountType: '',
        // 步骤
        step: 1,
        // 是否同意条例
        agree: false,
        dataForm: {
          password: '',
          confirmPassword: '',
          captcha: '',
          uuid: '',
          mobile: '',
          smsCode: ''
        },
        // 验证码路径
        captchaPath: '',
        dataRule: {
          password: [
            { required: true, message: '新密码不能为空', trigger: 'blur' },
            { validator: validateConfirmPassword, trigger: 'blur' }
          ],
          confirmPassword: [
            { required: true, message: '确认密码不能为空', trigger: 'blur' },
            { validator: passwordValidate2, trigger: 'blur' }
          ],
          mobile: [
            { validator: checkMobile, trigger: 'blur' }
          ],
          smsCode: [{ validator: checkSmsCode, trigger: 'blur' }]
        },
        // 发送短信成功
        smsSuc: false,
        // 下次发送时间
        smsTime: 60,
        // 按钮是否禁用
        codeBtnDisabled: false,
        loading: false,
        subLoading: false,
        isH5: false
      }
    },
    created () {
      this.accountType = this.$route.params.accountType
      this.getCaptcha()
      this.$nextTick(() => {
        this.isH5 = this.$route.params.h5 || ''
      })
    },
    computed: {
      winWidth :{
        get () { return  document.documentElement.clientWidth }
      },
    },
    methods: {
      // 获取验证码
      getCaptcha () {
        this.dataForm.uuid = getUUID()
        this.captchaPath = this.$http.adornUrl(`/captcha.jpg?uuid=${this.dataForm.uuid}`)
      },
      // 修改密码
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.subLoading = true
            this.$http({
              url: this.$http.adornUrl(this.$api.SYS.USER.FORGET_PASSWORD),
              method: 'post',
              data: this.$http.adornData({
                'accountType': this.accountType,
                'password': encode64(this.$encryptECB(this.dataForm.password)),
                'mobile': this.dataForm.mobile,
                'smsCode': encode64(this.dataForm.smsCode),
                'smsTerminal': 'SMS_TERMINAL_REGISTER'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.subLoading = false
                this.$message.success('修改成功')
                setTimeout(function () {
                  this.$router.replace({name: this.isH5 ? 'h5-login' : 'login'})
                }.bind(this), 500)
              } else {
                this.subLoading = false
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      // 校验短信验证码是否正确
      verifySmsCode () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.loading = true
            this.$http({
              url: this.$http.adornUrl(this.$api.SYS.V_CODE.VERIFY),
              method: 'post',
              data: this.$http.adornData({
                'uuid': this.dataForm.uuid,
                'captcha': this.dataForm.captcha,
                'mobile': this.dataForm.mobile,
                'smsCode': encode64(this.dataForm.smsCode),
                'smsTerminal': 'SMS_TERMINAL_VALID'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.step++
                this.loading = true
              } else {
                this.loading = false
                this.$message.error(data.msg)
                this.getCaptcha()
              }
            })
          }
        })
      },
      getMsgCode () {
        if (!isMobile(this.dataForm.mobile)) {
          this.$message.error('手机格式不正确')
          return
        }
        if(this.$refs['slider']) {
          // 未通过验证时，提示错误信息并返回
          if (!this.$refs['slider'].confirmSuccess) {
            this.$message.error('请拖动滑块验证')
            return
          }
        }
        let _this = this
        _this.$http({
          url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
          method: 'post',
          data: _this.$http.adornData({
            'mobile': _this.dataForm.mobile,
            'smsTerminal': 'SMS_TERMINAL_REGISTER'
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.success('短信发送成功，请注意查收')
            _this.smsSuc = true
            _this.smsTime = '60s后可重新获取'
            _this.countdown(60)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      // 计时器
      countdown (time) {
        if (time < 1) {
          this.codeBtnDisabled = false
          this.smsSuc = false
          return
        }
        this.smsSuc = true
        this.smsTime = time
        setTimeout(this.countdown, 1000, --time)
      }
    }
  }
</script>

<style scoped>
  #login {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    background-image: linear-gradient(50deg, #007bff, #51f9fb);
    height: 100vh;
  }

  body {
    width: 100%;
    height: 100vh;
  }

  .login-box {
    width: 30rem;
    min-height: 20rem;
    /*margin-left: 10rem;*/
    background: #ffffff;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .login-box .iconfont {
    /*margin-right: -2.4rem;*/
    margin-left: 1.3rem;
    z-index: 999;
    color: rgba(0,0,0,.6);
    font-size: 1.2rem;
  }
  .login-box .form-control {
    padding-left: 2.8rem;
    line-height: 2rem;
  }
  .login-box .btn {
    line-height: 2rem;
  }
  .left-img img{
    width: 32rem;
    height: 32rem;
  }
  .logo-box {
    margin-top: -7rem;
  }
  .logo-box .wrap{
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #ffffff;
  }
  .logo-box .wrap img{
    width: 5rem;
    height: 5rem;
  }
  .qrcode {
    line-height: 100%;
    height: 100%;
    overflow: hidden;
  }
  .iconfont.icon-yanzhengma {
    font-size: 14px;
    margin-left: 6px;
    color: #C0C4CC;
  }
  .qrcode img {
    width:100%;
    height: 100%;
    /*height: 2.5em;*/
    cursor: pointer;
    /*margin-top: 0.2rem;*/
  }
  @media only screen and (max-width: 850px) {
    .login-phone{
      height: 50rem !important;
    }
  }
</style>
